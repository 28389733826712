<template>
  <section class="l-content">
    <el-card class="l-condition" name="hTable">
      <el-form :inline="true" :model="condition">
        <el-form-item label="项目分类">
          <el-radio-group v-model="condition.projectType" size="small" @change="getPageData()">
            <el-radio-button label="asset">资产类</el-radio-button>
            <el-radio-button label="duty">管理类</el-radio-button>
            <el-radio-button label="item">其他类</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="项目状态">
          <el-radio-group v-model="condition.projectState" size="small" @change="getPageData()">
            <el-radio-button label="">全部</el-radio-button>
            <el-radio-button label="0">未启动</el-radio-button>
            <el-radio-button label="1">运行中</el-radio-button>
            <el-radio-button label="9">已关闭</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="责任部门">
          <l-select v-model="condition.departId" :remote="$api.departList" @change="getPageData()"></l-select>
        </el-form-item>
        <el-form-item label="关键字">
          <el-input v-model="condition.keyword" size="small" placeholder="项目名称|责任人"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getPageData()">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="warning" icon="el-icon-plus" size="mini" v-if="condition.projectType == 'asset'" @click="addAssetProject">新增风控项目-资产类</el-button>
          <el-button type="warning" icon="el-icon-plus" size="mini" v-else-if="condition.projectType == 'duty'" @click="addDutyProject">新增风控项目-管理类</el-button>
          <el-button type="warning" icon="el-icon-plus" size="mini" v-else-if="condition.projectType == 'item'" @click="addItemProject">新增风控项目-其他类</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="doExport()">导出</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-divider></el-divider>
    <el-card v-if="pageData">
      <l-table :option="tableOption" :data="pageData.list">
        <template slot="projectName" slot-scope="scope">
          <el-button type="text" @click="$site.view_safely_regular_project_detail(scope.row.projectId)">{{ scope.row.projectName }}</el-button>
        </template>
        <template slot="projectState" slot-scope="scope">
          <span v-if="scope.row.projectState == 9" class="text-red">已关闭</span>
          <span v-else-if="scope.row.projectState == 0" class="text-blue">未启动</span>
          <span v-else-if="scope.row.projectState == 1" class="text-green">运行中</span>
        </template>
        <template slot="triggerName" slot-scope="scope">
          <el-popover placement="left-start" :title="scope.row.projectName" width="200" trigger="hover" :content="scope.row.triggerDesc">
            <span slot="reference">{{ scope.row.triggerName }}</span>
          </el-popover>
        </template>
        <template slot="oper123" slot-scope="scope">
          <el-button type="primary" icon="el-icon-edit" size="mini" v-if="scope.row.projectState !== 9" @click="edit(scope.row)">修改</el-button>
          <el-button type="danger" icon="el-icon-close" size="mini" v-if="scope.row.projectState !== 9" @click="close(scope.row)">关闭</el-button>
        </template>
        <template slot="oper" slot-scope="scope">
          <el-dropdown type="primary" size="mini" trigger="click" @command="operCommand(scope.row, $event)">
            <span class="el-dropdown-link"> 相关操作<i class="el-icon-arrow-down el-icon--right"></i> </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="edit">修改项目</el-dropdown-item>
              <el-dropdown-item divided command="trigger">设置管控周期</el-dropdown-item>
              <el-dropdown-item divided command="close"><span class="text-red"> 关闭项目</span></el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </l-table>
      <l-pager :page="pageData" @change="getPageData"></l-pager>
    </el-card>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import assetSelector from './_project_asset_selector'
import projectAddoredit from './_project_addoredit'
import projectTriggerAddoredit from './_project_trigger_addoredit'

export default {
  computed: {
    ...mapState(['userInfo'])
  },
  name: 'SafelyRegularProject',
  data() {
    return {
      tableOption: {
        stripe: true,
        columns: [
          { label: '序号', type: 'index', fixed: true },
          { label: '项目名称', slot: 'projectName', align: 'left', overflow: true, fixed: true, width: 150 },
          { label: '项目分类', prop: 'projectTypeName', fixed: true, width: 70 },
          { label: '潜在风险', prop: 'riskDesc', width: 120 },
          { label: '风险状态', prop: 'riskState', width: 70 },
          { label: '风险时态', prop: 'riskTimeState', width: 70 },
          { label: '区域', prop: 'areaCode' },
          { label: '危险因素分类', prop: 'riskFactorCodeName', width: 100 },
          { label: '风险管控等级', prop: 'riskLevelName', width: 100 },
          { label: 'L值', prop: 'lec_l', width: 50, filter: (a, b) => (b.analysisType === 0 ? '-' : a) },
          { label: 'E值', prop: 'lec_e', width: 50, filter: (a, b) => (b.analysisType === 0 ? '-' : a) },
          { label: 'C值', prop: 'lec_c', width: 50, filter: (a, b) => (b.analysisType === 0 ? '-' : a) },
          { label: 'LEC', prop: 'lec', width: 50, filter: (a, b) => (b.analysisType === 0 ? '-' : a) },
          { label: '管控要求', prop: 'requirement', width: 150, overflow: true },
          { label: '管控标准', prop: 'standardNo', width: 100 },
          { label: '实施部门', prop: 'effectDepartName', width: 100 },
          { label: '实施人', prop: 'effectUserName', width: 70 },
          { label: '责任部门', prop: 'departName', fixed: 'right', width: 100 },
          { label: '责任人', prop: 'userName', fixed: 'right', width: 70 },
          { label: '管控周期', slot: 'triggerName', fixed: 'right' },
          { label: '状态', slot: 'projectState', fixed: 'right', width: 60 },
          { label: '', slot: 'oper', width: 100, fixed: 'right' }
        ]
      },
      condition: {
        keyword: '',
        // enterpriseCode: this.userInfo.enterpriseCode,
        departId: '',
        projectType: 'asset',
        projectState: '',
        pageIndex: 1,
        pageSize: 10
      },
      pageData: null
    }
  },
  created() {
    this.getPageData()
  },
  methods: {
    async getPageData(pageOption) {
      Object.assign(this.condition, pageOption)
      this.pageData = await this.$lgh.get('api/enterprise/safely/regular/project/pagedlist', ['enterpriseCode', this.condition])
    },
    operCommand(item, command) {
      if (command === 'edit') {
        this.edit(item)
      } else if (command === 'close') {
        this.close(item)
      } else if (command === 'trigger') {
        this.setTrigger(item)
      }
    },
    async addAssetProject() {
      var asset = await this.$lgh.openDialog({
        ins: assetSelector,
        title: '选择资产',
        data: {}
      })
      if (asset) {
        this.add({
          assetId: asset.assetId,
          projectType: 'asset',
          projectName: asset.assetName + '_' + asset.assetCode,
          riskFactorCode: 2,
          departId: asset.departId,
          userId: asset.userId,
          userName: asset.userName,
          triggerInfo: {
            enterpriseCode: this.userInfo.enterpriseCode,
            triggerType: 'cycle'
          }
        })
      }
    },
    addDutyProject() {
      this.add({
        projectType: 'duty',
        riskFactorCode: 4
      })
    },
    addItemProject() {
      this.add({
        projectType: 'item',
        riskFactorCode: 4
      })
    },
    async add(data) {
      await this.$lgh.openDialog({
        ins: projectAddoredit,
        title: '新增风控项目',
        data: {
          dataInfo: Object.assign(
            {
              enterpriseCode: this.userInfo.enterpriseCode,
              createUser: this.userInfo.fullName,
              riskState: '正常',
              riskTimeState: '现在',
              analysisType: 0,
              riskLevel: 4,
              lec_l: 0,
              lec_e: 0,
              lec_c: 0,
              lec: 0
            },
            data
          )
        }
      })
      this.$lgh.toastSuccess('新建项目信息成功')

      this.getPageData()
    },
    edit(item) {
      this.$lgh
        .get('api/enterprise/safely/regular/project/info', {
          projectId: item.projectId
        })
        .then(async (res) => {
          await this.$lgh.openDialog({
            ins: projectAddoredit,
            title: '修改风控项目',
            data: {
              departList: this.departList,
              dataInfo: res
            }
          })

          this.$lgh.toastSuccess('修改项目信息成功')
          this.getPageData()
        })
    },
    close(item) {
      this.$lgh.msgConfirm('确认关闭此风控项目?', async () => {
        await this.$lgh.get('api/enterprise/safely/regular/project/close', {
          projectId: item.projectId
        })
        this.$lgh.notifySuccess('关闭成功', item.projectName + '项目已关闭')
        this.getPageData()
      })
    },
    del(item) {
      this.$lgh.msgConfirm('确认删除项目信息?', async () => {
        await this.$lgh.get('api/enterprise/safely/regular/project/delete', {
          projectId: item.projectId
        })
        this.$lgh.notifySuccess('删除成功', item.projectName + '项目信息已删除')
        this.getPageData()
      })
    },
    setTrigger(item) {
      this.$lgh
        .get('api/enterprise/safely/regular/project/trigger/info', {
          projectId: item.projectId,
          triggerId: item.triggerId,
          withDefault: true
        })
        .then(async (res) => {
          if (!res.projectId) {
            res.projectId = item.projectId
            res.enterpriseCode = item.enterpriseCode
          }
          await this.$lgh.openDialog({
            ins: projectTriggerAddoredit,
            title: '设置风控周期',
            data: {
              dataInfo: item,
              triggerInfo: res
            }
          })

          this.$lgh.toastSuccess('设置成功')
          this.getPageData()
        })
    },
    doExport() {
      const h = this.$createElement

      this.$lgh.get('api/enterprise/safely/regular/project/export', ['enterpriseCode', this.condition]).then((res) => {
        this.$lgh.msgSuccess(h('a', { attrs: { target: '_blank', href: res.link_url } }, '点击下载'))
      })
    }
  }
}
</script>
<style>
.el-dropdown-link {
  font-size: 12px;
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
</style>
